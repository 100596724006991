<template>
	<div>
		<form v-on:submit.prevent="save">
			<div class="row mb-3">
				<div class="col-12 col-md-6 col-lg-8">
					<h1>Configuration Value: {{config.config_key}}</h1>
				</div>
				<div class="col-12 col-md-6 col-lg-4 text-right">
					<button type="button" class="btn btn-light ml-1" v-on:click="close()"><i class="fa-regular fa-times" aria-hidden="true"></i> Close</button>
					<button type="submit" class="btn btn-primary ml-1"><i class="fa-regular fa-save" aria-hidden="true"></i> Save</button>
				</div>
			</div>

			<div class="row mb-5">
				<div class="col-12 col-lg-6">
					<div class="card mb-3">
						<div class="card-header"><b>Configuration</b></div>

						<div class="card-body">
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="config_label" class="required">Configuration Label</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="config_label" :value="config.config_label" class="form-control" required />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="config_key" class="required">Configuration Key</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<input type="text" id="config_key" :value="config.config_key" class="form-control" disabled required />
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="config_value" class="required">Configuration Value</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<textarea id="config_value" v-model="config.config_value" class="form-control" required></textarea>
								</div>
							</div>
							<div class="form-row">
								<div class="col-12 col-md-6 col-lg-4 col-xl-3">
									<label for="encrypted">Encrypted</label>
								</div>
								<div class="col-12 col-md-6 col-lg-8 col-xl-9">
									<div class="custom-control custom-switch">
										<input type="checkbox" class="custom-control-input" id="encrypted" value="1" :checked="config.encrypt" disabled>
										<label class="custom-control-label" for="encrypted"></label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'Config',
		data() {
			return {
				// required_permissions: ["IDDQD", "ADMIN"],
				config: {config_id: null, config_label: "", config_key: "", config_value: "", encrypted: 1},
			}
		},
		mounted()
		{
			this.load();
		},
		computed:
		{
			...mapGetters([
				"user"
			])
		},
		methods:
		{
			load()
			{
				var config_id = this.$route.params.config_id;

				if(!config_id || config_id <= 0)
				{
					return;
				}

				this.$store.commit("startLoading");

				this.CORS('GET', "/config/" + config_id, null,
					(data) =>
					{
						this.config = data;
						this.$store.commit("stopLoading");
					},
					() =>
					{
						this.$store.commit("stopLoading");
						this.showError("Error", "Error loading configuration.", true, null);
					});
			},
			save()
			{
				this.$store.commit("startLoading");

				this.CORS("PUT", "/config/" + this.config.config_id, JSON.stringify(this.config),
					(data) =>
					{
						this.config = data;
						this.$store.commit("stopLoading");
						this.$router.push("/config").catch(() => { /* ignore error */ });
					},
					(response) =>
					{
						this.$store.commit("stopLoading");
						this.showError("Error", "Error saving configuration: " + response.responseText, true, null);
					});
			},
			close()
			{
				this.$router.push("/config");
			}
		},
		watch: {
			'$route' (to, from)
			{
				if(to == from)
				{
					return;
				}

				this.load();
			}
		}
	}
</script>
